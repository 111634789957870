<template>
    <v-container fluid>
        <v-card elevation="0" class="px-3">
            <v-card-title class="text-h4">
                Client Configuration
            </v-card-title>
        </v-card>
        <v-card :height="configureClientDimension.outerCardHeight" flat class="overflow-y-auto">
            <v-row no-gutters>
                <v-col cols="12">
                    <v-card elevation="0" class="px-3 mt-3">
                        <v-card-title> Kiosk Locations</v-card-title>
                        <!-- Multiple kioks in same State and Location  -->
                        <v-row no-gutters>
                            <v-col cols="3">
                                <v-text-field label="Court Name / Kiosk Location" outlined class="px-2"
                                    v-model="courtInfo.name"></v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field label="Kiosk Alias" hint="Ex. D1, D8" outlined class="px-2"
                                    v-model="courtInfo.alias"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-select label="Court Type" outlined :items="items" v-model="courtInfo.courtType"
                                    class="px-2">
                                    <!-- <template v-slot:selection="{ item, index }">
                                        <v-chip v-if="index === 0">
                                            <span>{{ item }}</span>
                                        </v-chip>
                                        <span v-if="index === 1" class="grey--text text-caption">
                                            (+{{ courtInfo.courtType.length - 1 }} others)
                                        </span>
                                    </template> -->
                                </v-select>
                            </v-col>
                            <v-col cols="2" class="pt-2">
                                <v-btn text color="primary" @click="addCourts()"><v-icon left>mdi-plus</v-icon>Add
                                    Location</v-btn>
                            </v-col>
                            <v-col cols="2"></v-col>
                        </v-row>
                        <v-card-text class="pt-0">
                            <v-chip v-for="(court, i) in clientDetails" :key="i" class="mx-1" close
                                @click:close="removeCourtLocation(court)"> {{ court.name }}</v-chip>
                        </v-card-text>
                    </v-card>
                </v-col>
                <!-- Kiosk Languages Selection - to support multiple languages  -->
                <v-col cols="12">
                    <v-card elevation="0" class="px-3 mt-3">
                        <v-card-title> Select Languages </v-card-title>
                        <v-row no-gutters>
                            <v-col cols="4">
                                <v-select label="Kiosk Languages" outlined :items="languages" item-text="name"
                                    item-value="code" return-object single-line v-model="selectedLanguage" class="px-2">
                                    <template v-slot:selection="{ item, index }">
                                        <v-chip v-if="index === 0">
                                            <span>{{ item.name }}</span>
                                        </v-chip>
                                        <span v-if="index === 1" class="grey--text text-caption">
                                            (+{{ selectedLanguage.length - 1 }} others)
                                        </span>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="2" class="pt-2">
                                <v-btn text color="primary" @click="addLanguages()"><v-icon left>mdi-plus</v-icon>Add
                                    Language</v-btn>
                            </v-col>
                            <v-col cols="2"></v-col>
                        </v-row>
                        <v-card-text class="pt-0">
                            <v-chip v-for="(lang, i) in languageList" :key="i" class="mx-1" close
                                @click:close="removeLanguage(lang)"> {{ lang.name }}</v-chip>
                        </v-card-text>
                    </v-card>
                </v-col>
                <!-- Kiosk Modules selection  -->
                <v-col cols="12">
                    <v-card elevation="0" class="px-3 mt-3">
                        <v-card-title> Select Modules - Way Finding</v-card-title>
                        <v-row no-gutters>
                            <v-col cols="4">
                                <v-select label="Kiosk Modules" outlined :items="modules" item-text="name" item-value="code"
                                    return-object single-line v-model="selectedModule" class="px-2">
                                    <template v-slot:selection="{ item, index }">
                                        <v-chip v-if="index === 0">
                                            <span>{{ item.name }}</span>
                                        </v-chip>
                                        <span v-if="index === 1" class="grey--text text-caption">
                                            (+{{ selectedModule.length - 1 }} others)
                                        </span>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="2" class="pt-2">
                                <v-btn text color="primary" @click="addModules()"><v-icon left>mdi-plus</v-icon>Add
                                    Module</v-btn>
                            </v-col>
                            <v-col cols="2"></v-col>
                        </v-row>
                        <v-card-text class="pt-0">
                            <v-chip v-for="(module, i) in modulesList" :key="i" class="mx-1" close
                                @click:close="removeModule(module)"> {{ module.name }}</v-chip>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-card elevation="0" class="px-3 mt-3">
                        <v-card-title> Select Modules - Form Filing </v-card-title>
                        <v-row no-gutters>
                            <v-col cols="4">
                                <v-select label="Form Filing Kiosk Modules" outlined :items="efileModules" item-text="name" item-value="code"
                                    return-object single-line v-model="selectedEfileModule" class="px-2">
                                    <template v-slot:selection="{ item, index }">
                                        <v-chip v-if="index === 0">
                                            <span>{{ item.name }}</span>
                                        </v-chip>
                                        <span v-if="index === 1" class="grey--text text-caption">
                                            (+{{ selectedEfileModule.length - 1 }} others)
                                        </span>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="2" class="pt-2">
                                <v-btn text color="primary" @click="addEfileModules()"><v-icon left>mdi-plus</v-icon>Add
                                    Module</v-btn>
                            </v-col>
                            <v-col cols="2"></v-col>
                        </v-row>
                        <v-card-text class="pt-0">
                            <v-chip v-for="(module, i) in efileModulesList" :key="i" class="mx-1" close
                                @click:close="removeEfileModules(module)"> {{ module.name }}</v-chip>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-row no-gutters class="mx-5 mt-2">
                        <v-col cols="3">
                            <v-text-field v-model="kioskRole" outlined label="Kiosk Role"
                                hint="Ex. Way Finding, Form Filing"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-btn text color="primary" class="my-3" @click="addKioskRoles()"><v-icon>mdi-plus</v-icon>Kiosk Role</v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text class="pt-0">
                        <v-chip v-for="(role, i) in kioskRoles" :key="i" class="mx-1" close
                            @click:close="removeKioskRoles(role)"> {{ role }}</v-chip>
                    </v-card-text>
                </v-col>
                <v-col cols="12">
                    <v-switch label="Telepresence" v-model="telepresence" class="mx-5"></v-switch>
                </v-col>
            </v-row>
        </v-card>
        <v-row no-gutters>
            <v-col cols="12" align="center" justify="center">
                <v-btn v-if="!appData.configured" class="px-3 mt-3" color="primary" width="400" height="45"
                    @click="configureClientSetting()" outlined><v-icon left>mdi-content-save-cog</v-icon> Save Changes
                </v-btn>
                <v-btn v-else class="px-3 mt-3" color="primary" width="400" height="45"
                    @click="updateConfigureClientSetting()" outlined><v-icon left>mdi-content-save-cog</v-icon> Update
                    Changes
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'configureClient',
    data() {
        return {
            items: ['Admin', 'Clerk'],
            languages: [{ name: 'English', code: 'en' }, { name: 'Spanish', code: 'es-US' }, { name: 'Chinese', code: 'zh' }, { name: 'Arabic', code: 'ar' }, { name: 'French', code: 'fr' },
            { name: 'Vietnamese', code: 'vi' }, { name: 'German', code: 'gr' }],
            modules: [{ name: "FAQs", alias: "faq" }, { name: "Maps", alias: "map" }, { name: "Forms", alias: "form" }, { name: "Today's Hearing", alias: "hearing" },
            { name: "Jury Check-In", alias: 'checkin' }, { name: "Payments", alias: 'payment' }],
            efileModules: [{ name: "Civil & Family", alias: "civil", link: "https://dksuperiorclerk.com/civil/" }, { name: "Criminal", alias: "criminal", link: "https://dksuperiorclerk.com/criminal/" }, 
            { name: "Appeals", alias: "appeal", link: "https://dksuperiorclerk.com/appeals/"}, { name: "Family Law", alias: "family", link: "https://dekalbsuperiorcourt.com/family-law2/the-center/" },
            { name: "Trade Name", alias: 'trade', link: "https://dksuperiorclerk.com/trade-names/" }, { name: "Odyssey Navigator", alias: 'odyssey', link: "https://ars-custom-action/?openLocalApp=TylerNavigator" }],
            selectedLanguage: null,
            selectedModule: null,
            selectedEfileModule: null,
            courtInfo: { name: '', alias: '', courtType: [] },
            languageList: [],
            modulesList: [],
            efileModulesList: [],
            clientDetails: [],
            telepresence: false,
            kioskRoles: [],
            kioskRole: ""
        }
    },
    computed: {
        ...mapGetters(['getClientConfig', 'appData', 'configureClientDimension'])
    },
    methods: {
        addCourts() {
            this.clientDetails.push(this.courtInfo)
            this.courtInfo = { name: '', alias: '', courtType: [] }
        },
        addLanguages() {
            this.languageList.push(this.selectedLanguage)
            this.selectedLanguage = null
        },
        addModules() {
            this.modulesList.push(this.selectedModule)
            this.selectedModule = null
        },
        addEfileModules(){
            this.efileModulesList.push(this.selectedEfileModule)
            this.selectedEfileModule = null
        },
        addKioskRoles() {
            this.kioskRoles.push(this.kioskRole)
            this.kioskRole = ""
        },
        configureClientSetting() {
            this.$store.dispatch('saveClientConfiguration', {
                courtInfo: this.clientDetails,
                languages: this.languageList,
                modules: this.modulesList,
                telepresence: this.telepresence,
                kioskRoles: this.kioskRoles,
                efileModules: this.efileModulesList
            })
            this.$store.dispatch("showSnackBar", {
                text: 'Client Configuration Saved',
                color: "success",
            });
        },
        updateConfigureClientSetting() {
            this.$store.dispatch('updateClientConfiguration', {
                courtInfo: this.clientDetails,
                languages: this.languageList,
                modules: this.modulesList,
                telepresence: this.telepresence,
                kioskRoles: this.kioskRoles,
                efileModules: this.efileModulesList
            })
            this.$store.dispatch("showSnackBar", {
                text: 'Client Configuration Updated',
                color: "success",
            });
        },
        removeModule(module) {
            console.log('Removed Module', module)
            this.modulesList = this.modulesList.filter(mod => mod.name !== module.name)
        },
        removeEfileModules(module){
            this.efileModulesList = this.efileModulesList.filter(mod => mod.name !== module.name)
        },
        removeLanguage(lang) {
            console.log('Removed language', lang)
            this.languageList = this.languageList.filter(language => language.name !== lang.name)
        },
        removeCourtLocation(court) {
            console.log('Removed Court kiosk', court)
            this.clientDetails = this.clientDetails.filter(client => client.name !== court.name)
        },
        removeKioskRoles(role) {
            this.kioskRoles = this.kioskRoles.filter(kioskRole => kioskRole !== role)
        }
    },
    mounted() {
        console.log('At mounted on Configure Client: ', this.getClientConfig)
        if (this.getClientConfig !== null) {
            this.languageList = [...this.getClientConfig.languages]
            this.modulesList = [...this.getClientConfig.modules]
            this.clientDetails = [...this.getClientConfig.courtInfo]
            this.telepresence = this.getClientConfig.telepresence
            this.efileModulesList = [...this.getClientConfig.efileModules]
        }
    }
}
</script>